

import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Seo from "../../components/common/seo"
import Layout from "../../components/common/layout"
import Modal from "react-modal"
import Hero from "../../components/members-zone/hero.js"

import DownloadImg from "../../../static/assets/image/Data-Suite-Download-button-120x115.jpg"
import setupImg1 from "../../../static/assets/image/setupImg1.png"
import setupImg2 from "../../../static/assets/image/setupImg2.png"
import setupImg3 from "../../../static/assets/image/setupImg3.png"
import setupImg4 from "../../../static/assets/image/setupImg4.png"
import setupImg5 from "../../../static/assets/image/setupImg5.png"
import setupImg6 from "../../../static/assets/image/setupImg6.png"
import setupImg7 from "../../../static/assets/image/setupImg7.png"
// import setupImg8 from "../static/assets/image/setupImg8.png"
import updateImg1 from "../../../static/assets/image/setupImg1.png"
import updateImg2 from "../../../static/assets/image/setupImg2.png"
import updateImg3 from "../../../static/assets/image/setupImg3.png"
import updateImg4 from "../../../static/assets/image/setupImg4.png"
import updateImg5 from "../../../static/assets/image/setupImg5.png"

const MembersZone = ({ data }) => {

  const [modalIsOpen, setIsOpen] = useState(false)
  function openModal() {
    setIsOpen(true)
  }
  function closeModal() {
    setIsOpen(false)
  }
    
 const [modalIsOpenTwo, setIsOpenTwo] = useState(false)
  function openModalTwo() {
    setIsOpenTwo(true)
  }
  function closeModalTwo() {
    setIsOpenTwo(false)
  }
  
  const [modalIsOpenThree, setIsOpenThree] = useState(false)
  function openModalThree() {
    setIsOpenThree(true)
  }
  function closeModalThree() {
    setIsOpenThree(false)
  }
    
const [modalIsOpenFour, setIsOpenFour] = useState(false)
  function openModalFour() {
    setIsOpenFour(true)
  }
  function closeModalFour() {
    setIsOpenFour(false)
  }

const [modalIsOpenFive, setIsOpenFive] = useState(false)
  function openModalFive() {
    setIsOpenFive(true)
  }
  function closeModalFive() {
    setIsOpenFive(false)
  }
    
const [modalIsOpenSix, setIsOpenSix] = useState(false)
  function openModalSix() {
    setIsOpenSix(true)
  }
  function closeModalSix() {
    setIsOpenSix(false)
  }
    
const [modalIsOpenSeven, setIsOpenSeven] = useState(false)
  function openModalSeven() {
    setIsOpenSeven(true)
  }
  function closeModalSeven() {
    setIsOpenSeven(false)
  }
  
const [modalIsOpenEight, setIsOpenEight] = useState(false)
  function openModalEight() {
    setIsOpenEight(true)
  }
  function closeModalEight() {
    setIsOpenEight(false)
  }    

const [modalIsOpenNine, setIsOpenNine] = useState(false)
  function openModalNine() {
    setIsOpenNine(true)
  }
  function closeModalNine() {
    setIsOpenNine(false)
  }  
  
  const [modalIsOpenTen, setIsOpenTen] = useState(false)
  function openModalTen() {
    setIsOpenTen(true)
  }
  function closeModalTen() {
    setIsOpenTen(false)
  }  
  
  const [modalIsOpenEleven, setIsOpenEleven] = useState(false)
  function openModalEleven() {
    setIsOpenEleven(true)
  }
  function closeModalEleven() {
    setIsOpenEleven(false)
  }  
  
  const [modalIsOpenTwelve, setIsOpenTwelve] = useState(false)
  function openModalTwelve() {
    setIsOpenTwelve(true)
  }
  function closeModalTwelve() {
    setIsOpenTwelve(false)
  }   
  
 const [modalIsOpenThirteen, setIsOpenThirteen] = useState(false)
  function openModalThirteen() {
    setIsOpenThirteen(true)
  }
  function closeModalThirteen() {
    setIsOpenThirteen(false)
  } 
  
const [modalIsOpenFourteen, setIsOpenFourteen] = useState(false)
  function openModalFourteen() {
    setIsOpenFourteen(true)
  }
  function closeModalFourteen() {
    setIsOpenFourteen(false)
} 

const [modalIsOpenFifteen, setIsOpenFifteen] = useState(false)
  function openModalFifteen() {
    setIsOpenFifteen(true)
  }
  function closeModalFifteen() {
    setIsOpenFifteen(false)
    } 
    
const [modalIsOpenSixteen, setIsOpenSixteen] = useState(false)
  function openModalSixteen() {
    setIsOpenSixteen(true)
  }
  function closeModalSixteen() {
    setIsOpenSixteen(false)
} 

const [modalIsOpenSeventeen, setIsOpenSeventeen] = useState(false)
  function openModalSeventeen() {
    setIsOpenSeventeen(true)
  }
  function closeModalSeventeen() {
    setIsOpenSeventeen(false)
} 

          useEffect(() => {
      if (window.location.href.indexOf("fr") > -1) {
        setTimeout(() => {
        document.querySelector("body").classList.add("fr");
      }, 500)
        // alert("hello")
      }
      }, [])
    
  Modal.setAppElement("#___gatsby")

    return (
        <div className="membersZone">
            <Layout>
                <Seo title="Members Zone" />
                <Hero />
                <div className="membersZoneWrapper indMember en-only">
                        <h2>GRP and Reach Frequency</h2>
                        <h3>Calculation</h3>
                        <p>When calculating outdoor GRPs and reach-frequency (R/F), buyers and sellers utilize an OOH industry standard that excludes exposures attributed to non-residents of the market/s participating in the campaign.</p>
                        <hr />
                        <h3>Definition of outdoor GRP</h3>
                        <p>
                            Total exposures to an outdoor campaign <strong>X</strong> the In-Market Factor * x 100 / Market population
                        </p>
                        <p>
                            * Definition of In-Market Factor – the percentage of people exposed to outdoor advertising that represents residents or deemed-residents of the market. A deemed resident resides within 100 kms of a CMA boundary and travels into the CMA market 4 days or more per week based on statistically reliable GPS travel pattern studies conducted by COMMB and approved by the COMMB Research Committee.
                        </p>
                        <p>Deemed-residents are currently recognized for the markets of Toronto, Montreal and Vancouver only.</p>
                        <hr />
                        <h3>Definition of Reach</h3>
                        <p>The % of the target population that has been exposed to the advertising campaign at least one.</p>
                        <hr />
                        <h3>Definition of Frequency</h3>
                        <p>The average number of times each person reached within the target group has been exposed to the advertising. GRP and R/F reporting is available through COMMB’s proprietary media planning software, COMMBNavigator®. Information on the R/F model can be found in Navigator R/F. </p>
                </div>
                <div className="membersZoneWrapper indMember fr-only">
                        <h2>À propos du modèle de portée-fréquence</h2>
                        <h3>Le Calcul</h3>
                        <p>Le modèle de portée-fréquence du NavigateurCOMMBMD calcule l’exposition probable d’une cible donnée à une campagne d’affichage en faisant la corrélation entre les données de circulation de COMMB et les kilomètres parcourus selon l’étude RAE.</p>            
                        <p>
                          L’étude RAE est la plus importante étude consommateurs au Canada. Publiée deux fois l’an, elle constitue un outil de référence auquel se fient plus de 40 des grandes agences de publicité et de nombreuses entreprises de média au Canada.
                        </p>
                        <hr />
                        <h3>Pourquoi le modèle de portée-fréquence du NavigateurCOMMBMD est-il si fiable?</h3>
                        <p>
                            Le module utilise un modèle mathématique ajusté en fonction de données de déplacements réels, ce qui lui permet d’offrir un grand degré de fiabilité aux planificateurs, acheteurs et fournisseurs médias.
                        </p>
                        <hr />
                        <p>
                          Au cours d’une étude réalisée sur sept ans, COMMB a analysé les déplacements des consommateurs à partir de données fournies par des modules GPS installés dans les véhicules de répondants vivant dans cinq marchés au Canada. Ceci lui a permis de recueillir de l’information sur plus de 265 000 kilomètres parcourus ayant généré quelque 980 000 expositions à ses affiches publicitaires. Ces expositions ont été établies en faisant la corrélation entre les trajets parcourus et les emplacements d’affichage.
                        </p>
                        <hr />
                        <p>
                          Les résultats ainsi obtenus sont sûrs : leur taux moyen de variation n’est que de 3 % sur l’ensemble des données recueillies.
                        </p>
                        <hr />
                        <h3>Le NavigateurCOMMBMD. Des données plus fiables. Une planification plus efficace.</h3>
                </div>                
            </Layout>
        </div>
    )
}

export default MembersZone